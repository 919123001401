<template>
  <div>
    <vue-headful :title="pageTitle" />
    <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
      <h1 class="is-size-6 has-text-white" style="padding: 5px 0px"
      >{{code}} {{description}}</h1>
    </div>

    <center>
      <b>{{message}}</b><br>
      <small>
        If you think this page was reached in error, please contact the system administrator.
      </small>
    </center>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      code: '401',
      message: 'You accessed a page without the required permissions',
      description: 'Not Authorized'
    }
  },
  computed: {
    ...mapState(['activeSite', 'clientSession']),
    pageTitle () {
      return `${this.code} ${this.description} - ` + this.activeSite.displayName
    }
  },
  mounted () {
    this.code = this.$route.params.code

    if (this.code.toString() === '401') {
      this.message = 'You accessed a page without the required permissions'
      this.description = 'Not Authorized'
    } else if (this.code.toString() === '403') {
      this.message = 'You attempted to access a page that you do not have permission to access'
      this.description = 'Forbidden'
    } else if (this.code.toString() === '404') {
      this.message = 'The page you requested was not found'
      this.description = 'Not Found'
    } else if (this.code.toString() === '500') {
      this.message = 'The server encountered an error'
      this.description = 'Internal Server Error'
    } else if (this.code.toString() === '503') {
      this.message = 'The server is temporarily unavailable'
      this.description = 'Service Unavailable'
    } else {
      this.message = 'An unknown error occurred'
      this.description = 'Unknown Error'
    }
  }
}
</script>
